import React from 'react'
import { ChakraProvider, theme } from '@chakra-ui/react'

require('prismjs/themes/prism-tomorrow.css')

const _theme = {
  ...theme,
  fontWeights: { ...theme.fontWeights, bolder: 900 },
}

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={_theme}>{element}</ChakraProvider>
)
